/**
 * Makes a random key.
 * https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 * @return {String} The random key
 */
function keyGenerator() {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 20; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}


/**
 * Hides the password fields and updates their values to a random string.
 */
export default function() {
  if (document.querySelector('.tn-account-update-page')) {
    const password = document.querySelector('.tn-password');
    const password_confirm = document.querySelector('.tn-confirm-password');
    const random_key = keyGenerator();

    password.parentNode.classList.add('hide');
    password_confirm.parentNode.classList.add('hide');

    password.value = random_key;
    password_confirm.value = random_key;
  } 
}