/**
 * Checks if this is a french site.
 * @return {Boolean} True if french.
 */
export default function() {
  let is_french = false;

  if (window.location.href.indexOf('billetterie.droitsdelapersonne.ca') !== -1
    || window.location.href.indexOf('/mcdp-tnew-test.tnhs.cloud') !== -1
    // Add the domain for local testing - if the page is prefixed with 'fr-'.
    || window.location.href.indexOf('http://127.0.0.1.xip.io:9001/fr-') === 0
  ) {
    is_french = true;
  }

  return is_french;
}
