/**
 * Add an ajaxComplete handler for all XMLHttpRequests.
 * @param {String|Function} callback Callback to invoke.
 */
function addAjaxCompleteHandler(callback) {
  const send = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function() {
    this.addEventListener('load', function() {
      callback.apply();
    });
    return send.apply(this, arguments);
  };
}

export default addAjaxCompleteHandler;