/* global google */

/**
 * Initializes the google map
 * @return {[type]} [description]
 */
const initMap = function() {
  // https://snazzymaps.com/editor/customize/15
  const map_style = [
    {
      'featureType': 'administrative',
      'elementType': 'all',
      'stylers': [
        {
          'saturation': '-100'
        }
      ]
    },
    {
      'featureType': 'administrative.province',
      'elementType': 'all',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'all',
      'stylers': [
        {
          'saturation': -100
        },
        {
          'lightness': 65
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'all',
      'stylers': [
        {
          'saturation': -100
        },
        {
          'lightness': '50'
        },
        {
          'visibility': 'simplified'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'all',
      'stylers': [
        {
          'saturation': '-100'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'all',
      'stylers': [
        {
          'visibility': 'simplified'
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'all',
      'stylers': [
        {
          'lightness': '30'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'all',
      'stylers': [
        {
          'lightness': '40'
        }
      ]
    },
    {
      'featureType': 'transit',
      'elementType': 'all',
      'stylers': [
        {
          'saturation': -100
        },
        {
          'visibility': 'simplified'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'hue': '#ffff00'
        },
        {
          'lightness': -25
        },
        {
          'saturation': -97
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels',
      'stylers': [
        {
          'lightness': -25
        },
        {
          'saturation': -100
        }
      ]
    }
  ];
  let zoom = 16;
  // let geocoder = new google.maps.Geocoder();
  let center;
  let maps = [...document.getElementsByClassName('js-gmap')];
  const default_options = {
    styles: map_style,
    zoom: zoom
  };
  let gmaps = [];

  maps.forEach(node => {
    let geocoder = new google.maps.Geocoder();
    let map;
    let options = default_options;

    // If static map, update the options to prevent user interactions.
    if (node.getAttribute('data-type') === 'static') {
      options = Object.assign(options, {
        disableDefaultUI: true,
        draggable: false,
        scrollwheel: false,
        panControl: false,
        maxZoom: zoom,
        minZoom: zoom,
        zoom: zoom
      });
    }

    // Get the map center.
    const center_coords = node.getAttribute('data-center').split(',');
    const address = node.getAttribute('data-address');

    // If there's an explicit center, set it, otherwise geocode address.
    if (!!center_coords) {
      center = {lat: parseFloat(center_coords[0]), lng: parseFloat(center_coords[1])};
    } else if (!!address) {
      geocoder.geocode({'address': address}, function(results, status) {
        if (status === 'OK') {
          const center_location = results[0].geometry.location;
          center = {lat: center_location.lat(), lng: center_location.lng()};
        } else {
          console.log(`Geocode was not successful for the following reason: ${status}`);
        }
      });
    }

    // If center is set, center the map.
    if (center) {
      options = Object.assign(options, {
        center: center
      });
    }

    // Create the map.
    map = new google.maps.Map(node, options);
    gmaps.push(map);

    // Add marker/s.

    // Get data-markers (json-encoded).
    const markers = JSON.parse(node.getAttribute('data-markers'));

    // Loop through markers, set data and create marker.
    if (!!markers && markers instanceof Array) {
      markers.forEach((marker_data) => {
        if (!!marker_data) {
          marker_data = Object.assign(marker_data, {
            map: map
          });

          // Replace 'center' keyword with center lat/lng object.
          if (marker_data.position === 'center') {
            marker_data.position = center;
          }

          const marker = new google.maps.Marker(marker_data);
        }
      });
    }

  });


  // Center the map on window resize
  // TODO: Add throttling
  /*
  google.maps.event.addDomListener(window, 'resize', function() {
    gmaps.forEach((gmap) => {
      gmap.setCenter(center);
    });
  });
  */
};

export default initMap;
