/**
 * Some formatting for the calendar view.
 */
function initCalendarView() {

  // Remove &nbsp; from event titles so they wrap properly.
  [...document.querySelectorAll('.tn-events-calendar__event-name')].forEach(elem => {
    elem.innerHTML = elem.innerHTML.replace('&nbsp;', ' ');
  });
}

export default initCalendarView;