import isStaging from './staging-links';

const is_staging = isStaging();
const en_host = is_staging ? 'https://cmhr-tnew-test.tnhs.cloud' : 'https://ticketing.humanrights.ca/';
const assets_host = is_staging ? 'https://styles.staging.humanrights.ca/tnew' : 'https://styles.humanrights.ca/tnew';

const translations = {
  lang: 'fr',
  language: {
    text: 'English',
    href: en_host,
    lang: 'en'
  },
  site_navigation: 'Site navigation',
  main: [
    {
      text: 'Entrée générale',
      href: '/events?view=calendar&k=Entrée'
    },
    {
      text: 'Visites guidées',
      href: '/events?view=calendar&k=Visites_Guidées'
    },
    {
      text: 'Adhésions',
      href: '/adhesion'
    },
    {
      text: 'Événements',
      href: '/events?view=calendar&k=Événements_spéciaux'
    },
    {
      text: 'Expositions temporaires',
      href: '/events?view=calendar&k=Exposition_Temporaire'
    }
  ],
  nav_logo: {
    alt: 'MCDP',
    src: `${assets_host}/images/Logo-CMHR-Nav-fr.svg`
  },

  footer_header: 'Pied de page',
  legal_heading: 'Liens juridiques',
  footer_links_heading: 'Navigation de pied de page',
  footer_navigation: [
    {
      text: 'À propos',
      href: 'https://droitsdelapersonne.ca/a-propos-du-musee'
    },
    {
      text: 'Location de salles',
      href: 'https://droitsdelapersonne.ca/a-propos/location-de-salles'
    },
    {
      text: 'L\'édifice',
      href: 'https://droitsdelapersonne.ca/a-propos/ledifice'
    },
    {
      text: 'Notre personnel',
      href: 'https://droitsdelapersonne.ca/a-propos/notre-personnel'
    },
    {
      text: 'Amis du MCDP',
      href: 'http://amisdumcdp.com/'
    },
    {
      text: 'Nous joindre',
      href: 'https://droitsdelapersonne.ca/a-propos/nous-joindre'
    },
  ],

  legal: [
    {
      text: 'Énoncé de confidentialité',
      href: 'https://droitsdelapersonne.ca/enonce-de-confidentialite-du-musee-canadien-pour-les-droits-de-la-personne'
    },
    {
      text: 'Avis juridiques',
      href: 'https://droitsdelapersonne.ca/avis-juridique'
    },
  ],

  footer_logo: {
    alt: 'MCDP',
    src: `${assets_host}/images/Logo-CMHR-Nav-fr.svg`
  },

  logo_link: {
    href: 'https://droitsdelapersonne.ca/'
  },

  trip_advisor: {
    src: `${assets_host}/images/Trip-advisor-logo-fr.svg`,
    alt: 'Insigne de certificat d\'excellence Trip Advisor'
  },

  trip_advisor_link: {
    href: 'https://fr.tripadvisor.ca/Attraction_Review-g154954-d2515076-Reviews-Canadian_Museum_for_Human_Rights-Winnipeg_Manitoba.html'
  },

  find_us: 'Nous trouver',
  dialogue: 'Participer au dialogue',
  phone: 'Téléphone : 204–289-2000',
  toll_free: 'Sans frais : 1–877-877‑6037',
  tty: 'ATS : 204–289-2050',
  email: 'info@droitsdelapersonne.ca',

  social: [
    'https://x.com/mcdp',
    'https://www.instagram.com/cmhr_mcdp/',
    'https://www.facebook.com/AuMCDP/',
    'https://www.youtube.com/user/MuseeDroitsPersonne'
  ],


  map: 'Carte',
  phone_numbers: '',
  copyrights: '©2018 Musée canadien pour les droits de la personne',

  calendar: {
    months: {
      jan: 'Janvier',
      feb: 'Février',
      mar: 'Mars',
      apr: 'Avril',
      may: 'Mai',
      jun: 'Juin',
      jul: 'Juillet',
      aug: 'Août',
      sep: 'Septembre',
      oct: 'Octobre',
      nov: 'Novembre',
      dec: 'Décembre'
    },
    days: {
      sunday: 'Dimanche',
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi'
    },
    days_short: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'], // Sunday - Saturday
    days_tiny: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
    calendar: 'Calendrier',
    next_day: 'Jour prochain',
    previous_day: 'Jour précédent',
    next_week: 'Semaine prochaine ',
    previous_week: 'Semaine précédente',
    next_month: 'Mois prochain',
    previous_month: 'Mois précédent',
    next_year: 'Année prochaine',
    previous_year: 'Année précédente',
    disabled: 'Désactivé',
    help_text: 'Appuyez sur les touches fléchées pour naviguer par jour, les touches « PageUp » et « PageDown » pour naviguer par mois, les touches « Alt+PageUp » et « Alt+PageDown » pour naviguer par année, ou la touche « Escape » pour annuler..',
    filter_heading: 'Filtrer par période',
    filter_to: 'à',
    filter_btn: 'Réinitialiser la période',
    tabs: ['Jour', 'Semaine', 'Mois']
  },

  packages_no_results_btn: 'Retour à la liste',
  packages_loading: 'Veuillez patienter pendant que nous récupérons les événements.',
  required: 'Obligatoire'
};

export default translations;
