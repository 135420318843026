/* globals $A */
import { datepickerButtonUpdate } from './translation';
import isFrench from './utils/french-url';
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

/**
 * Convert date string 'Month Day, Year' to ISO yyyy-mm-dd
 * @param date_string
 * @return null|string
 */
function dateStringToIso(date_string) {
  const date_parts = date_string.replace(',', '').split(' ');
  if (date_parts.length < 3) {
    return null;
  }
  const month = (`0${months.indexOf(date_parts[0])}`).slice(-2);
  const day = (`0${date_parts[1]}`).slice(-2);
  const year = date_parts[2];
  return `${year}-${month}-${day}`;
}


/**
 * Convert date string 'dd/mm/yyyy' to ISO yyyy-mm-dd
 * @param date_string
 * @return null|string
 */
function dateDmyToIso(date_string) {
  const date_parts = date_string.split('/');
  if (date_parts.length < 3) {
    return null;
  }
  return `${date_parts[2]}-${date_parts[1]}-${date_parts[0]}`;
}

/**
 * Creates the snippet to be used in the filter
 * @param  {String} dates Today's date
 * @return {[type]}       [description]
 */
function createSnippet(dates) {
  const to_date = (!!dates.search_end) ? dates.search_end : dates.next_date;
  const from_date = (!!dates.search_start) ? dates.search_start : dates.this_date;
  const disabled = (!!window.location.search) ? '' : 'disabled=""';
  return '<div class="tn-event-listing-view__controls-container">' +
    '<h2 class="sr-only">List View</h2>' +
    '<div class="tn-events-list-view__controls">' +
      '<h3 class="tn-event-listing__controls-heading js-filter-heading">Filter by Date Range</h3>' +
      '<div class="tn-events-list-view__date-range">' +
        '<div class="tn-events-list-view__datepicker-container tn-events-list-view__datepicker-container--from">' +
          '<div class="tn-btn-datepicker " style="">' +
            '<p class="sr-only">Select start date</p>' +
            '<div class="tn-btn-datepicker__btn-container">' +
              '<button type="button" id="js-datepicker-from" class="btn btn-default btn-lg tn-btn-datepicker__btn" aria-expanded="false">' +
                `<span class="tn-btn-datepicker__date-display">${from_date}</span>` +
                '<span class="tn-btn-datepicker__icon-container" aria-hidden="true">' +
                  '<span class="glyphicon glyphicon-calendar"></span>' +
                '</span>' +
              '</button>' +
            '</div>' +
            '<input type="text" id="tn-event-list-input-date-from" name="tn-event-list-input-date-from" class="tn-btn-datepicker__input sr-only" aria-hidden="true" tabindex="-1">' +
          '</div>' +
        '</div>' +
        '<div class="tn-events-list-view__datepicker-divider">' +
          '<div class="tn-events-list-view__datepicker-divider-label js-to-label">to</div>' +
        '</div>' +
        '<div class="tn-events-list-view__datepicker-container tn-events-list-view__datepicker-container--to">' +
          '<div class="tn-btn-datepicker " style="">' +
            '<p class="sr-only">Select end date</p>' +
            '<div class="tn-btn-datepicker__btn-container">' +

              '<button type="button" id="js-datepicker-to" class="btn btn-default btn-lg tn-btn-datepicker__btn" aria-expanded="false">' +
                `<span class="tn-btn-datepicker__date-display">${to_date}</span>` +
                '<span class="tn-btn-datepicker__icon-container" aria-hidden="true">' +
                  '<span class="glyphicon glyphicon-calendar"></span>' +
                '</span>' +
              '</button>' +
            '</div>' +
            '<input type="text" id="tn-event-list-input-date-to" name="tn-event-list-input-date-to" class="tn-btn-datepicker__input sr-only" aria-hidden="true" tabindex="-1">' +
          '</div>' +
        '</div>' +
        '<div class="tn-events-list-view__btn-reset-dates-container">' +
          `<button id="js-packages-reset-filter" class="tn-events-list-view__btn-reset-dates btn btn-default btn-sm" ${disabled}>Reset Date Range</button>` +
        '</div>' +
      '</div>' +
    '</div>' +
  '</div>';
}


/**
 * Make only the first month visible and hide the rest
 * in the list.
 * @return {DOM Element} The last visible element in the packages list.
 */
function hideOtherPackages(date) {
  let last_date = document.querySelector('.tn-prod-list-item--package:last-child .tn-package-list-item__date-range');

  // If the page hasn't been filtered.
  if (!!!window.location.search) {
    const dates = document.querySelectorAll('.tn-package-list-item__date-range');
    const packages = [...document.querySelectorAll('.tn-prod-list-item--package')];
    let dmy_date_regex = /\d{2}\/\d{2}\/\d{4}/;
    let is_hidden = false;
    let current_date;

    // Convert date to ISO.
    date = dmy_date_regex.exec(date) ? dateDmyToIso(date) : dateStringToIso(date);

    [...dates].forEach((element, index) => {
      const cloned = element.cloneNode(true);

      [...cloned.querySelectorAll('.sr-only')].forEach(span => {
        cloned.removeChild(span);
      });

      current_date = cloned.textContent.trim();
      if (current_date) {
        is_hidden = false;

        // Convert current date to ISO.
        current_date = dmy_date_regex.exec(current_date) ? dateDmyToIso(current_date) : dateStringToIso(current_date);
        is_hidden = current_date && date && current_date < date;        
        
        if (is_hidden) {
          packages[index].classList.add('hide');
        } else {
          last_date = [...dates][index];
        }
      }
    });
  }

  return last_date;
}


/**
 * Gets the dates for the buttons.
 * @return {[type]} [description]
 */
function getDates() {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const this_date = `${months[month]} ${today.getDate()}, ${year}`;
  let last_date = document.querySelector('.tn-prod-list-item--package:last-child .tn-package-list-item__date-range').cloneNode(true);
  let next_date = hideOtherPackages(this_date).cloneNode(true);
  let search_start;
  let search_end;

  // Set the next date to the last date in the list.
  [...last_date.querySelectorAll('.sr-only')].forEach(span => {
    last_date.removeChild(span);
  });

  [...next_date.querySelectorAll('.sr-only')].forEach(span => {
    next_date.removeChild(span);
  });

  // Check URL query params.
  if (!!window.location.search) {

    // Date can be passed in query params.
    const query_vars = window.location.search.substring(1).split('&');
    query_vars.forEach(str => {
      const pair = str.split('=');
      query_vars[pair[0]] = pair[1];
    });

    if (query_vars.hasOwnProperty('startDate')) {
      const start_array = query_vars.startDate.split('-');
      search_start = `${months[start_array[1] - 1]} ${start_array[2]}, ${start_array[0]}`;
    }
    if (query_vars.hasOwnProperty('endDate')) {
      const start_array = query_vars.endDate.split('-');
      search_end = `${months[start_array[1] - 1]} ${start_array[2]}, ${start_array[0]}`;
    }
  }

  next_date = next_date.textContent.trim();
  last_date = last_date.textContent.trim();

  // If it's written in numerical format dd/mm/yyy, change it to word format.
  if (next_date.indexOf('/') >= 0) {
    let split_next_date = next_date.split('/');
    next_date = `${months[split_next_date[1] - 1]} ${split_next_date[0]}, ${split_next_date[2]}`;
  }

  return { this_date, next_date, today, search_start, search_end, last_date };
}


/**
 * [updatePicker description]
 * @param  {Object} calendar Datepicker object
 * @param  {String} id The id of the datepicker
 * @return {[type]}          [description]
 */
function updatePicker(calendar, id) {
  let btn = document.querySelector(`#${id} .tn-btn-datepicker__date-display`);
  btn.innerHTML = `${months[calendar.date.getMonth()]} ${calendar.date.getDate()}, ${calendar.date.getFullYear()}`;

  // Close datepicker after making a selection
  calendar.close();

  if (isFrench()) {
    datepickerButtonUpdate(btn);
  }
}


/**
 * Filters the packages based on the date range selected.
 * @param  {Object} dates The start and end default dates.
 * @param  {Boolean} reset Resets the query_string if it's true.
 * @return {[type]}       [description]
 */
function filter(dates, reset) {
  const from = $A.reg['js-packages-datepicker-from'];
  const to = $A.reg['js-packages-datepicker-to'];
  let from_date;
  let query_string = '';

  document.querySelector('.js-packages-loader').classList.remove('hide');
  document.querySelector('.tn-package-list').classList.add('hide');

  if (!from.range.current) {
    from_date = `${dates.today.getFullYear()}-${dates.today.getMonth() + 1}-${dates.today.getDate()}`;
  } else {
    from_date = `${from.range.current.year}-${from.range.current.month + 1}-${from.range.current.mDay}`;
  }

  query_string = (reset) ? '' : `?startDate=${from_date}&endDate=${to.range.current.year}-${to.range.current.month + 1}-${to.range.current.mDay}`;

  window.location.href = window.location.origin + window.location.pathname + query_string;
}


/**
 * Initializes the datepicker.
 * @return {[type]} [description]
 */
export default function() {
  const container = document.createElement('div');
  const reference = document.querySelector('.tn-package-list');

  if (reference) {
    const style = document.createElement('link');
    const dates = getDates();
    const snippet = createSnippet(dates);
    const options = {
      className: 'tn-datepicker',
      posAnchor: 0,
      audibleDateFormat: 'D, dddd MMMM YYYY',
      minDate: new Date(dates.this_date),
      maxDate: new Date(dates.last_date),
      autoPosition: 0
    };
    let reset;
    const loader = document.createElement('div');
    loader.classList.add('tn-event-listing-view--loading');
    loader.classList.add('tn-event-listing__primary-view');
    loader.classList.add('js-packages-loader');
    loader.classList.add('hide');
    loader.innerHTML = '<div class="tn-event-listing-busy-indicator">' +
         '<div class="tn-event-listing-busy-indicator__content">Please wait</div>' +
       '</div>';

    container.classList.add('datepicker-container');
    container.innerHTML = snippet;
    document.querySelector('.tn-package-listing-page').insertBefore(container, reference);
    document.querySelector('.tn-package-listing-page').insertBefore(loader, reference);
    style.href = 'https://production.tnew-assets.com/tnew/tnew-event-listing.1993679d943bc52ecf0ce6496e2f3ed0.css';
    style.rel = 'stylesheet';
    style.type = 'text/css';
    document.querySelector('main').appendChild(style);

    reset = document.getElementById('js-packages-reset-filter');

    $A.bind(window, 'load', function() {
      // Syntax : setCalendar( ID , TriggeringElement , TargetEditField , EnableComments , clickHandler , config )
      $A.setCalendar('js-packages-datepicker-from', document.getElementById('js-datepicker-from'), document.getElementById('tn-event-list-input-date-from'), false,
      (e, calendar) => {
        e.preventDefault();
        updatePicker(calendar, 'js-datepicker-from');
        reset.disabled = false;
      }, Object.assign(options, {
        initialDate: (!!dates.search_start) ? new Date(dates.search_start) : new Date(dates.this_date)
      }));

      $A.setCalendar('js-packages-datepicker-to', document.getElementById('js-datepicker-to'), document.getElementById('tn-event-list-input-date-to'), false,
      (e, calendar) => {
        e.preventDefault();
        updatePicker(calendar, 'js-datepicker-to');
        reset.disabled = false;
        filter(dates);
      }, Object.assign(options, {
        initialDate: (!!dates.search_end) ? new Date(dates.search_end) : new Date(dates.next_date)
      }));


      // Reset the date pickers.
      reset.addEventListener('click', e => {
        window.location.href = window.location.origin + window.location.pathname;
      });
    });
  } else {
    // No results. Add a button to return back to the listing.
    const btn = document.createElement('a');
    btn.textContent = 'Return to listing';
    btn.classList.add('btn', 'js-return-to-listing');      // adding the second class not working.
    btn.href = window.location.origin + window.location.pathname;
    const listing = document.querySelector('.tn-package-listing-page');
    if (listing) {
      listing.appendChild(btn);
    }
  }

};
