/**
 * Test if the current url is local dev or not.
 * @returns {Boolean} True if local dev, otherwise false.
 */
function isDev() {
  return window.location.origin.indexOf('http://127.0.0.1.xip.io') === 0;
}

/**
 * Run some tasks on local dev - e.g. setup ajax functionality.
 */
function initDev() {
  if (isDev()) {
    // Add local ajax function.
    document.querySelectorAll('[data-ajax]').forEach(elem => {
      elem.addEventListener('click', e => {
        const url = `/ajax/${elem.getAttribute('data-ajax')}`;
        const target_id = elem.getAttribute('data-ajax-target');
        const target = target_id ? document.getElementById(target_id) : null;
        if (!target) {
          return;
        }

        // Use XMLHttpRequest since that's what Tessitura uses.
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
          target.innerHTML = this.responseText;
        };
        xhr.open('GET', url);
        xhr.send();
      });
    });
  }
}

export default initDev;

export { isDev };

