/**
 * Updates all the header links to the staging links.
 */
function setStagingLinks() {
  if (window.location.origin === 'https://cmhr-tnew-test.tnhs.cloud') {
    let french_links = document.querySelectorAll('.js-tr-language');

    [...french_links].forEach(link => {
      link.href = link.href.replace('https://billetterie.droitsdelapersonne.ca', 'https://mcdp-tnew-test.tnhs.cloud');
    });
  }
}


/**
 * Test if the current url is staging or not.
 * @returns {Boolean} True if staging, otherwise false.
 */
function isStaging() {
  let is_staging = false;

  if (window.location.origin === 'https://cmhr-tnew-test.tnhs.cloud' ||
    window.location.origin === 'https://mcdp-tnew-test.tnhs.cloud') {
    is_staging = true;
  }

  return is_staging;
}


export default setStagingLinks;

export { isStaging };
