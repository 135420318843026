/**
 * It is a class that contains keycodes needed for the application.
 * @type {Object}
 */
class KeyCodes {
  /**
   * KeyCodes() creates an object to contain keycodes needed for the application.
   */
  constructor() {
    // Define values for keycodes
    this.backspace = 8;
    this.tab = 9;
    this.shift = 16;
    this.enter = 13;
    this.esc = 27;

    this.space = 32;
    this.pageup = 33;
    this.pagedown = 34;
    this.end = 35;
    this.home = 36;

    this.up = 38;
    this.down = 40;
    this.right = 39;
    this.left = 37;

    this.del = 46;

  } // end KeyCodes
}

export default KeyCodes;

export const keys = Object.freeze(new KeyCodes());
