import translations from './translation-data';


/**
 * Utility function that returns the translated string.
 * @param  {String}  string       The string that contains the |.
 * @param  {Boolean} [is_en=true] Flag for english or french.
 * @return {String}               The translated string.
 */
const handlePipeTranslation = (string, is_en = true) => {
  const split_string = string.split('|');

  if (is_en) {
    return split_string[0];
  } else {
    return (!!split_string[1]) ? split_string[1] : split_string[0];
  }
};

/**
 * Translates price types.
 * @param {string} string        The string that contains the |. If a $ is present after the |, the $ and anthing after it is included regardless.
 * @param {Boolean} [is_en=true] Flag for english or french.
 * @return {String}               The translated string.
 */
const handlePriceTypeTranslation = (string, is_en = true) => {
  let priceText = '';

  if ((string.indexOf('|') > 0) && (string.indexOf('$') > string.indexOf('|'))) {
    const split_string = string.split(/(?=\$)/g);
    priceText = (!!split_string[1]) ? ` ${split_string[1]}` : '';
    string = split_string[0];
  }

  return handlePipeTranslation(string, is_en) + priceText;
};

/**
 * @param  {String}  string       The parent element with either of the following children classes:
 *    tn-package-list-item__description_en 
 *    tn-package-list-item__description_fr
 * @param  {Boolean} [is_en=true] Flag for english or french.

 */
const handleDivTranslation = (element, is_en = true) => {
  const childElem = is_en ? element.querySelector('.tn-package-list-item__description_fr') : element.querySelector('.tn-package-list-item__description_en');
  if (childElem) {
    element.removeChild(childElem);
  }
};

/**
 * Translate specific instances where english and french are separated by pipes.
 * @param  {Boolean} [is_en=true] Flag for english or french.
 */
const translatePipes = (is_en = true) => {
  const membership = document.querySelectorAll('.tn-ticket-selector__input-zone');
  const packages_headings = document.querySelectorAll('.tn-prod-list-item__property--heading a');
  const package_heading = document.querySelectorAll('.tn-flex-package-details__title, .tn-flex-package-details__title');
  const package_description = document.querySelectorAll('.tn-package-list-item__description');
  const cart_heading = document.querySelector('.tn-cart-item-summary__property--name');
  const cart_description = document.querySelectorAll('.tn-cart-item-detail__list-item--description');
  const packages_img_alt = document.querySelectorAll('.tn-prod-list-item__property--img-container img, .tn-flex-package-details__image');
  const price_types = document.querySelectorAll('.tn-ticket-selector__pricetype-name');
  const title = document.querySelector('title');
  const title_split = title.textContent.split('|');

  /**
   * Translates the node if it contains a |
   */
  const translateTextNodes = (node, callback) => {
    if (node.nodeName === '#text') {
      let text = node.textContent;
      text = text.trim();

      if (text.indexOf('|') > -1) {
        if (callback) {
          callback(text);
        } else {
          node.textContent = handlePipeTranslation(text, is_en);
        }
      }
    }
  };

  // Membership page
  [...membership].forEach((input) => {
    const children = input.parentNode.childNodes;
    [...children].forEach((child) => {
      translateTextNodes(child, (text) => {
        const split_text = text.split(':');
        split_text[0] = handlePipeTranslation(text.split(':')[0], is_en);
        text = split_text.join(':');

        child.textContent = text;
      });
    });
  });

  // Packages
  [...packages_headings, ...package_heading].forEach((item) => {
    item.innerHTML = handlePipeTranslation(item.textContent, is_en);
    item.innerHTML = item.innerHTML.replace('&nbsp;', ' ');
  });

  // Price types
  [...price_types].forEach((item) => {
    item.innerHTML = handlePriceTypeTranslation(item.textContent, is_en);
    item.innerHTML = item.innerHTML.replace('&nbsp;', ' ');
  });

  // Package image alt-text
  [...packages_img_alt].forEach(img => {
    img.alt = handlePipeTranslation(img.alt, is_en);
  });
  
  // Package descriptions 
  [...package_description].forEach(desc => {
    handleDivTranslation(desc, is_en);
  });
  
  // Cart headings
  if (cart_heading) {
    [...cart_heading.childNodes].forEach(child => {
      translateTextNodes(child);
    });
  }

  // Cart descriptions
  [...cart_description].forEach(desc => {
    [...desc.childNodes].forEach(child => {
      translateTextNodes(child);
    });
  });

  // Page titles
  if (title_split.length === 3) {
    title.textContent = `${handlePipeTranslation(`${title_split[0]}|${title_split[1]}`, is_en)} | ${title_split[2]}`;
  }
};


/**
 * Updates the string of a datepicker button.
 * @param  {DOMNode} btn The datepicker button
 * @return {[type]}     [description]
 */
const datepickerButtonUpdate = function(btn) {
  // If there is no /, we assume the date is written in words and then needs to be translated.
  if (btn.textContent.indexOf('/') === -1) {
    const string = btn.textContent.split(' ');
    const month = string[0];
    string[0] = String(Object.keys(translations.calendar.months).indexOf(month.slice(0, 3).toLowerCase()) + 1);
    string[0] = string[0].padStart(2, '0');

    // If the string contains the day (so mm/dd/yyy),
    // remove the comma and re-arrange as dd/mm/yyy
    if (string.length === 3) {
      string[1] = string[1].replace(',', '');
      string[1] = string[1].padStart(2, '0');
      btn.textContent = [string[1], string[0], string[2]].join('/');
    } else {
      btn.textContent = string.join('/');
    }
  }
};


/**
 * The month day picker uses a different date format so we handle it differently. The month should be displayed as "MMMM YYYY" e.g. "January 2020"
 * Also, need to update the month heading.
 */
const updateMonthDatepicker = function() {
  const btn = document.querySelector('.tn-events-calendar-view--month .tn-btn-datepicker__date-display');
  const headings = [...document.querySelectorAll('.tn-events-calendar-view--month .tn-event-listing-view__results-heading')];

  if (btn) {
    const string = btn.textContent.split(' ');
    const month = string[0];

    // translate the month name
    string[0] = translations.calendar.months[month.slice(0, 3).toLowerCase()];

    /**
    * If the month is already in French, for certain months it will not be able to translate properly.
    * For these circumstances, just use the original value.
    */
    if ((!string[0]) && (Object.values(translations.calendar.months).includes(month))) {
      string[0] = month;
    }

    if (string[0]) {
      let newMonthText = string.join(' ');
      if (btn.textContent.localeCompare(newMonthText) !== 0) { 
        btn.textContent = newMonthText;
      }

      if (headings.length > 0) {
        headings.forEach(heading => {
          if (heading.textContent.localeCompare(btn.textContent) !== 0) {
            heading.textContent = btn.textContent;
          }
        });
      }
    }
  } 
};


/**
 * Translates the calendar, week, and day views.
 * @return {[type]} [description]
 */
function translateCalendar() {
  const views = [...document.querySelectorAll('#tn-events-calendar-view-month, #tn-events-calendar-view-week, #tn-events-calendar-view-day')];


  /**
   * Updates the month calendar.
   */
  const updateMonth = () => {
    const month_days = document.querySelectorAll('.tn-events-calendar-view--month .tn-events-calendar__table thead th');

    // Update month view
    [...month_days].forEach((day, i) => {
      day.textContent = translations.calendar.days_short[i];
    });

    updateMonthDatepicker();
  };

  /**
   * Updates the week calendar.
   * @return {[type]} [description]
   */
  const updateWeek = () => {
    const week_days = document.querySelectorAll('.tn-events-calendar-view--week .tn-events-calendar__table thead th');
    [...week_days].forEach((day, i) => {
      const date = day.textContent.split(',')[1];
      day.textContent = `${translations.calendar.days_short[i]}, ${date}`;
    });
  };


  /**
   * Updates the day view
   */
  const updateDay = () => {
    const day_view_heading = document.querySelector('.tn-events-calendar__list-day-heading');

    if (day_view_heading) { // eslint-disable-line
      const translation = translations.calendar.days[day_view_heading.textContent.toLowerCase()];

      if (!!translation) {  // eslint-disable-line
        day_view_heading.textContent = translation;
      }
    }
  };

  updateMonth();
  updateWeek();
  updateDay();

  views.forEach(view => {
    const target = view;
    const config = { childList: true, subtree: true };
    const callback = (mutation_list) => {
      for (let mutation of mutation_list) {
        if (mutation.type === 'childList') {
          let node = [...mutation.addedNodes][0];

          if (node && node.classList) {
            switch (view.id) {    // eslint-disable-line
              case 'tn-events-calendar-view-month':
                updateMonth();
                break;
              case 'tn-events-calendar-view-week':
                updateWeek();
                break;
              default:
                updateDay();
                break;
            }
          }

          // We have to handle no results separately.
          if (mutation.target.classList.contains('tn-events-calendar__table-container') &&
              target.closest('.tn-events-calendar-view--month') &&
              mutation.target.querySelector('.tn-event-listing-view__no-results-content')) {
            [...document.querySelectorAll('.tn-events-calendar-view--month .tn-event-listing-view__results-heading')].forEach(heading => {
              heading.textContent = document.querySelector('.tn-events-calendar-view--month .tn-btn-datepicker__date-display').textContent;
            });
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(target, config);
  });
}


/**
 * Translates the datepicker
 */
function translateDatepicker() {  
  const packages_datepicker_btns = [...document.querySelectorAll('.tn-package-listing-page .tn-btn-datepicker__date-display')];
  const containers = [...document.querySelectorAll('.tn-btn-datepicker__btn-container')];

  // Update only the month datepicker
  updateMonthDatepicker();

  packages_datepicker_btns.forEach(btn => {
    datepickerButtonUpdate(btn);
  });

  // Nex/Prev day, week, month buttons
  [...document.querySelectorAll('.tn-btn-datepicker__btn-period-prev-next .sr-only')].forEach(btn => {
    const value = btn.textContent;
    btn.textContent = translations.calendar[value.toLowerCase().replace(' ', '_')];
  });

  /**
   * Update the visible part of the datepicker calendar
   */
  const updateVisibleCalendar = () => {
    const month = document.querySelector('.month:not(.btn) span');
    const day_of_week = document.querySelectorAll('.week');
    const month_name = month ? month.textContent : null;

    if (month_name) {
      const month_key = month_name.toLowerCase().slice(0, 3);
      if (translations.calendar.months[month_key]) {
        month.textContent = translations.calendar.months[month_key];
      }
    }


    [...day_of_week].forEach((day, i) => {
      const day_fr = translations.calendar.days[day.title.toLowerCase()];
      if (day_fr) {
        day.title = day_fr;
        day.innerHTML = `<span>${day_fr.slice(0, 1)}</span>`;
      }
    });
  };


  /**
   * Updates the aria labels
   */
  const updateAria = () => {
    const days = document.querySelectorAll('.day');
    [...document.querySelectorAll('[accesskey]')].forEach(selector => {
      const value = translations.calendar[selector.title.toLowerCase().split(' ').join('_')];
      selector.title = value;
      selector.setAttribute('aria-label', value);
    });

    [...days].forEach(day => {
      const aria_label = day.getAttribute('aria-label').split(' ');
      const day_of_week = aria_label[1].toLowerCase();

      aria_label[2] = translations.calendar.months[aria_label[2].toLowerCase().slice(0, 3)];
      aria_label[1] = translations.calendar.days[day_of_week];

      // EEE d MMMM YYYY
      day.setAttribute('aria-label', [aria_label[1], aria_label[0].replace(',', ''), aria_label[2], aria_label[3]].join(' '));

      if (day.classList.contains('disabled')) {
        day.title = translations.calendar.disabled;
      }
    });

  };


  containers.forEach(container => {
    const config = { childList: true, subtree: true };
    const callback = (mutation_list) => {
      for (let mutation of mutation_list) {
        const target = mutation.target;
        const added_nodes = [...mutation.addedNodes];

        if (target.classList.contains('tn-btn-datepicker__btn-container') && added_nodes.length === 1) {
          const datepicker = document.querySelector('.tn-datepicker');
          const calendar = document.querySelector('.calendar');
          updateVisibleCalendar();
          updateAria();

          datepicker.dataset.helptext = translations.calendar.help_text;
          calendar.setAttribute('aria-label', translations.calendar.calendar);
        }

        // We only update the datepicker for the month view only and it copies the text from
        if (target.classList.contains('tn-btn-datepicker__date-display') && target.closest('.tn-events-calendar-view--month')) {
          updateMonthDatepicker();
        }
      }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(container, config);
  });

  // Use event bubbling since we don't have access to the datepicker object in most cases.

  document.addEventListener('click', (e) => {
    // If the next/prev month buttons are clicked. These are the buttons
    // that sit on either side of the datepicker itself.
    if (e.target.classList.contains('tn-btn-datepicker__btn-period-prev-next')) {
      updateMonthDatepicker();
    }
  });
}


/**
 * Translate the day, week, month tabs on the event calendar view.
 * @return {[type]} [description]
 */
function translateTabs() {
  const tabs = [...document.querySelectorAll('.tn-event-listing-mode-tab-nav__list--secondary .tn-event-listing-mode-tab-nav__list-item')];

  tabs.forEach((tab, i) => {
    tab.textContent = translations.calendar.tabs[i];
  });
}


/**
 * Translates the filter for the packages pages only.
 * @return {[type]} [description]
 */
function translatePackagesFilter() {
  if (document.querySelector('.tn-package-listing-page')) {
    const return_btn = document.querySelector('.js-return-to-listing');

    if (return_btn) {
      document.querySelector('.js-return-to-listing').textContent = translations.packages_no_results_btn;
    } else {
      document.querySelector('.js-filter-heading').textContent = translations.calendar.filter_heading;
      document.querySelector('.js-to-label').textContent = translations.calendar.filter_to;
      document.querySelector('#js-packages-reset-filter').textContent = translations.calendar.filter_btn;
      document.querySelector('.js-packages-loader .tn-event-listing-busy-indicator__content').textContent = translations.packages_loading;
    }
  }
}

/**
 * Translates the "Required" text adjacent to required fields.
 */
function translateRequiredFlag() {
  const requiredFields = [...document.querySelectorAll('.tn-required-field')];
  requiredFields.forEach((requiredField, i) => {
    requiredField.textContent = translations.required;
  });
}

/**
 * Translates to French based on provided data.
 */
function translateToFr() {
  Object.keys(translations).forEach(key => {
    const data = translations[key];

    [...document.getElementsByClassName(`js-tr-${key}`)].forEach(elem => {
      if (typeof data === 'string') {
        if (key === 'email') {
          elem.removeAttribute('onclick');
          elem.onclick = () => window.open(`mailto:${data}`);
        } else if (key === 'lang') {
          elem.setAttribute('lang', data);
          return;
        }

        elem.textContent = data;
        return;
      }

      if (data instanceof Array) {
        const a_tags = [...elem.getElementsByTagName('a')];
        a_tags.forEach((a, index) => {
          const a_data = data[index];

          if (typeof a_data === 'string') {
            a.setAttribute('href', a_data);
          } else {
            a.textContent = a_data.text;
            a.setAttribute('href', a_data.href);
          }
        });

        return;
      }

      if (typeof data === 'object') {

        if (data.src) {
          elem.setAttribute('src', data.src);
        }

        if (data.alt) {
          elem.setAttribute('alt', data.alt);
        }

        if (data.href) {
          elem.setAttribute('href', data.href);
        }

        if (data.lang) {
          elem.setAttribute('lang', data.lang);
        }

        if (data.text) {
          elem.textContent = data.text;
        }
      }
    });
  });

  if (document.getElementById('tn-events-calendar-view')) {
    translateCalendar();
    translateTabs();
  }

  // We have to wait until these are ready before we can initialize.
  setTimeout(() => {
    translateDatepicker();
  }, 500);

  translatePackagesFilter();
  
  // The "Required" text must be translated as well.
  translateRequiredFlag();
}


window.test_french = translateToFr;
window.test_pipe = translatePipes;

export default translateToFr;
export { translatePipes, datepickerButtonUpdate };
