/**
 * Throttling function.
 * https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
 * @param  {function} func
 * @param  {number} limit throttling interval
 * @return {function} throttled function
 */
const throttle = (func, limit) => {
  let inThrottle;

  return function() {
    const args = arguments;
    const context = this;

    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
};

/**
 * returns viewport wdith
 * @return {[number]}
 */
export const viewportWidth = function() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
};


/**
 * returns viewport height
 * @return {[number]}
 */
export const viewportHeight = function() {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
};


/**
 * returns the media type
 * @param  {Integer} desktop The minimum desktop width
 * @return {String}         The viewport type
 */
export const mediaType = function(desktop) {
  let temp_viewport;
  let viewport_width;

  viewport_width = viewportWidth();

  if (viewport_width >= desktop) {
    temp_viewport = 'desktop';
  } else {
    temp_viewport = 'mobile';
  }

  return temp_viewport;
};


/**
 * the function attaches resize event listener to the window element,
 * if the viewport is changed it will trigger 'media_changed' event
 * @param  {[number]} desktop - viewport width
 * @return {[undefined]}
 */
export const mediaListenCustom = function(desktop, event_name) {
  // const $win = $(window);
  let viewport = mediaType(desktop);

  window.addEventListener('resize', throttle(function() {
    let temp_viewport = mediaType(desktop);
    let event;

    if (temp_viewport !== viewport) {
      viewport = temp_viewport;

      if (window.CustomEvent) {
        event = new CustomEvent(event_name, { detail: viewport });
      } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(event_name, true, true, viewport);
      }

      document.dispatchEvent(event);
    }
  }, 100));
};


/**
 * The default resize event listener for media change event.
 * @param  {[number]} desktop - viewport width
 * @return {[undefined]}
 */
export const mediaListen = function(desktop) {
  mediaListenCustom(desktop, 'media_changed');
};
