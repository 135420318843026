const dollar_regex = /^\$\d+(.\d{2})?$/;  // $xx.xx or $xx

/**
 * Parses the string inside the element and replaces it if it matches the regex.
 * @param  {DOM} element Element containing the string to parse
 * @return {[type]}         [description]
 */
function parseAndReplace(element) {
  if (element) {
    let string = element.textContent.trim();
    string = string.split(' ');

    for (let i = 0; i < string.length; i++) {
      if (dollar_regex.test(string[i])) {
        string[i] = string[i].replace('.', ',').replace('$', '').concat('$');
      }
    }

    element.textContent = string.join(' ');
  }
}


/**
 * Parses the sibling text. Removes whitespace and returns a textNode.
 * @param  {DOM} element The element containing the text.
 * @return {TextNode}    The textNode containing the price
 */
function parseSiblingText(element) {
  let text_node;
  let children = [...element.childNodes];

  children.forEach((child) => {
    if (child.nodeName === '#text') {
      if (child.textContent.indexOf('$') > -1) {
        child.textContent = child.textContent.trim();
        text_node = child;
      }
    }
  });

  return text_node;
}


/**
 * Localizes the currency to French format.
 * @return {[type]} [description]
 */
function localizeCurrencey() {
  const pricetype = document.querySelectorAll('.tn-ticket-selector__pricetype-fee-breakdown, .tn-ticket-selector__pricetype-name, .tn-ticket-selector__pricetype-zone-heading');
  const membership = [...document.querySelectorAll('.tn-ticket-selector__input-zone')];

  // The table needs to be structured properly wit the price in the last column.
  const gift_cert = document.querySelectorAll('.tn-gift-certificate-component td:last-child');

  // Shopping cart
  const cart_totals = document.querySelectorAll('.tn-cart-totals__value');
  const cart_prices = [...document.querySelectorAll('.tn-cart-item-summary__property--price-total, .tn-cart-item-detail__list-item--price')];
  const packages_prices = [...document.querySelectorAll('.tn-flex-selection__details div:first-child, .tn-ticket-selector__quantity-selected-price')];
  const packages_total = [...document.querySelectorAll('.tn-flex-cart__package-subtotal, .tn-ticket-selector__zone-subtotal-amount, .tn-flex-mobile-cart-drawer__summary-subtotal span')];
  const packages_watch = [...document.querySelectorAll('.tn-flex-cart__package-subtotal, .tn-flex-mobile-cart-drawer__summary-subtotal span')];
  const package_toggles_watch = [...document.querySelectorAll('.tn-ticket-selector__pricetype-zone-heading, .tn-ticket-selector__pricetype-individual-price, .tn-ticket-selector__pricetype-fee-breakdown, .tn-ticket-selector__quantity-selected-price')];

  let elements = [...pricetype, ...gift_cert, ...cart_totals];

  cart_prices.forEach((element) => {
    parseAndReplace(parseSiblingText(element));
  });

  // On Membership page, we have do a bit more parsing since the structure
  // is different.
  membership.forEach((input) => {
    parseAndReplace(parseSiblingText(input.parentNode));
  });

  // By default, we're expecting the string to be inside these elements.
  elements.forEach((element) => {
    const indv_price = element.querySelector('.tn-ticket-selector__pricetype-individual-price');
    let price = element;

    if (indv_price) {
      price = indv_price;
    }

    price.innerHTML = price.innerHTML.trim();   // Strip whitespace
    
    parseAndReplace(price);
  });

  // We only want to watch on the packages build page.  
  if (document.querySelector('.tn-flex-package-selections-page')) {
    // Packages prices are sometimes updated via ajax, so watch them
    // so we can change on the fly.
    const config = { childList: true, subtree: true };
    [...packages_watch, ...package_toggles_watch].forEach(price => {
      const callback = (mutation_list) => {
        for (let mutation of mutation_list) {
          if (mutation.type === 'childList') {
            parseAndReplace([...mutation.addedNodes][0]);
          }
        }
      };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      observer.observe(price, config);
    });

    // Watch the body so we can target the modal when it appears.
    const callback = (mutation_list) => {
      for (let mutation of mutation_list) {
        if (mutation.type === 'childList' && 
            mutation.target.classList.contains('modal-open') &&
            [...mutation.addedNodes].length > 0) {
          const node = [...mutation.addedNodes][0];
          
          if (node.classList.contains('tn-flex-cart-modal')) {
            const prices = node.querySelectorAll('.tn-flex-selection__details div:first-child');
            const total = node.querySelector('.tn-flex-cart__package-subtotal');
            
            prices.forEach(item => {
              parseAndReplace(parseSiblingText(item));
            });

            parseAndReplace(total);
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(document.body, config);
  }

  // Packages
  packages_prices.forEach(item => {
    parseAndReplace(parseSiblingText(item));
  });

  packages_total.forEach(item => {
    parseAndReplace(item);
  });
  
}



// Test function for local development.
window.localize = localizeCurrencey;

export default localizeCurrencey;
