import './vendor/Acc.DC.API.U.js';
import './vendor/calendar_generator.min.js';
import initNav from './nav';
import initMap from './google-map';
import translateToFr, { translatePipes } from './translation';
import setStagingLinks, { isStaging } from './staging-links';
import getIEVersion from './utils/getIE';
import localizeCurrencey from './localize-currency';
import initDatepicker from './datepicker';
import initCalendarView from './calendar-view';
import isFrench from './utils/french-url';
import initAccount from './modules/account';
import initDev from './local-dev';
import addAjaxCompleteHandler from './utils/ajax-complete';

document.addEventListener('DOMContentLoaded', () => {
  const sidebar = document.querySelector('.tn-event-detail__additional-events-container');
  

  if (sidebar && !sidebar.querySelector('.tn-additional-events__controls-container')) {
    sidebar.classList.add('hide');
  }

  initNav();
  initMap();

  if (document.querySelector('.tn-package-listing-page')) {
    initDatepicker();
  }
  if (document.getElementById('tn-events-calendar-view')) {
    initCalendarView();
  }

  /**
   * Updates the copyright year in the copyright message found in the footer of the page.
   */
  function updateCopyrightYear() {
    var currentYear = String(new Date().getFullYear());
    [...document.getElementsByClassName(`js-tr-copyrights`)].forEach(elem => {
      elem.innerText = elem.innerText.replace(/©(\d){4}/g, '©' + currentYear);
    });
  }

  /**
   * Wrap all translation/localization functions in one.
   */
  function handleTranslation() {
    // Translate to french
    if (isFrench()) {
      translateToFr();
      translatePipes(false);
      localizeCurrencey();
    } else {
      translatePipes();
    }

    // Must occur after translation to ensure change is applied
    updateCopyrightYear();
  }

  

  // Invoke translation handler now as well as on ajaxed content.
  handleTranslation();
  addAjaxCompleteHandler(handleTranslation);


  if (getIEVersion() > 0) {
    document.querySelector('body').classList.add('ie');
  }

  setStagingLinks();
    initAccount();    

  // Check if on local dev and add custom ajax functionality if so.
  initDev();
});
