/* global u */
/* eslint complexity: [1, 40] */
/* eslint max-depth: [1, 8] */
import { mediaType, mediaListenCustom } from './utils/viewport-size';
import getIE from './utils/getIE';
import { keys } from './utils/keycodes';


/**
 * Initialises the navbar functionality.
 * @return {[undefined]}
 */
export default function() {
  const nav_cont = document.querySelector('.js-header-container');
  const nav = document.querySelector('.js-header');
  const close_btn = document.querySelector('.js-close-nav-mobile');
  const CUSTOM_DESKTOP = 941;
  let scroll_memory;
  const IE = getIE();
  let nav_open = false;
  const tess_util_nav = document.querySelector('.tn-subnav-component');


  mediaListenCustom(CUSTOM_DESKTOP, 'media_changed_nav');

  nav.addEventListener('transitionend', e => {
    if (nav_open && e.propertyName === 'height') {
      nav_cont.classList.add('header--has-transitioned');
      scroll_memory = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      document.body.classList.add('no-scroll');

      // Force repaint on edge once only.
      if (IE > 11) {
        nav.classList.add('force-repaint');
        setTimeout(() => {
          nav.classList.remove('force-repaint');
        }, 0);
      }

    } else if (e.propertyName === 'height') {
      nav.classList.remove('header--before-after-transition');
      tess_util_nav.classList.remove('tn-subnav-component--zindex');
    }
  });


  close_btn.addEventListener('click', () => {
    nav.classList.add('header--before-after-transition');
    nav.classList.toggle('header--open');
    nav_open = !nav_open;
    tess_util_nav.classList.add('tn-subnav-component--zindex');

    if (!nav_open) {
      nav_cont.classList.remove('header--has-transitioned');
      // Sets overflow hidden on the body for mobile only.
      if (mediaType(CUSTOM_DESKTOP) === 'mobile') {
        document.body.classList.remove('no-scroll');
        window.scrollTo(0, scroll_memory);
        scroll_memory = 0;
      }
    }
  });

  document.addEventListener('keydown', e => {
    if (e.keyCode === keys.esc && nav_open) {
      close_btn.click();
    }
  });

  // clens up the mobile nav on resize to desktop
  document.addEventListener('media_changed_nav', e => {
    const media_type = e.detail;

    if (media_type === 'desktop') {
      nav_open = false;
      nav_cont.classList.remove('header--has-transitioned');
      nav.classList.remove('header--open');
      tess_util_nav.classList.remove('tn-subnav-component--zindex');

      // Removes overflow hidden on the body
      document.body.classList.remove('no-scroll');
    }
  });
}
